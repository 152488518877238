import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
/*
import TeamSlider from '../Slider/TeamSlider';
*/
import Spacing from '../Spacing';

export default function AboutPage() {
  pageTitle('About');

  const funfaceData = [
    {
      title: 'Digital works',
      factNumber: '48',
    },
    {
      title: 'Global happy clients',
      factNumber: '78',
    },
    {
      title: 'Industries served',
      factNumber: '15',
    },
    {
      title: 'Satisfaction rate',
      factNumber: '98%',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Creative Design Solutions by LabX"
              subtitle="About Our Studio"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              LabX is a design studio that specializes in UI/UX design, branding design, social media post design, and motion graphic design. Our mission is to create meaningful and impactful designs that help our clients achieve their goals and connect with their audiences.
              <p>
              <br></br>
              With a team of experienced and dedicated designers, we approach each project with creativity, attention to detail, and a deep understanding of our clients' needs. Our design process is collaborative and iterative, ensuring that we deliver solutions that are not only visually stunning but also effective and user-friendly.
              </p>
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/labxabout.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          {/*
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          */}
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          title="Goal Achievement"
          subtitle="Our satisfied customers are at the heart of what we do. We take pride in delivering exceptional design solutions that exceed expectations and help our clients achieve their goals."
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/labxabout-2.png"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>

          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Design with Purpose: LabX"
              subtitle="Why Choose Us"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              At LabX, we are committed to excellence and strive to exceed our clients' expectations with every project we take on. We are passionate about design and are always looking for new ways to innovate and push the boundaries of what's possible.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
              Whether you're a startup looking to establish your brand or an established company seeking to elevate your design game, LabX has the expertise and creativity to help you succeed. Get in touch with us today to learn more about our services and how we can help bring your design vision to life.              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      {/* End Why Choose Section */}


      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
