import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ContactPage() {
  pageTitle('Contact Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      toast.success('Your message has been successfully delivered.', {
        position: 'top-right',
        autoClose: 3000, // mesajın otomatik olarak kapanma süresi (ms cinsinden)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const formDatab = new FormData(e.target);
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyKORQThaD-rIkUJUJrzFbq_HXe3Rj5aq5lNkniJApl8x1lcTEIk0bE2ALMjNjc1kCe/exec",
        {
          method: "POST",
          body: formDatab,
        }
      );
      const data = await response.json();
      console.log(data);
      alert("Thank you for contacting us. We'll get back to you as soon as possible.");
    } catch (error) {
      console.error(error);
    };
  };
  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a project <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" className="row" onSubmit={handleSubmit}>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input 
                type="text" 
                className="cs-form_field"
                name="Name"
                value={name}
                onChange={(e) => setName(e.target.value)} 
                required
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input 
                type="email" 
                className="cs-form_field" 
                name="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)} 
                required
                />
                <Spacing lg="20" md="20" />
              </Div>
              {/*
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input 
                type="text" 
                className="cs-form_field"
                name="Projecttype"
                value={projectType}
                onChange={(e) => setProjecttype(e.target.value)} 
                required 
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input 
                name="Mobile"
                type="number" 
                className="cs-form_field"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)} 
                required 
                />
                <Spacing lg="20" md="20" />
              </Div>
               */}
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  name="Message"
                  type="text" 
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)} 
                  required
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" type="submit">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>        

              </Div>
              <ToastContainer />
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12034.23292610252!2d28.9983252!3d41.0567889!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9ec0e422ee9%3A0xe48bbf2454159e09!2sBilgiyi%20Ticarile%C5%9Ftirme%20Merkezi!5e0!3m2!1str!2str!4v1688553262422!5m2!1str!2str"
          allowFullScreen
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}
