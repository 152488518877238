import React from 'react'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <a className="cs-center" href="https://twitter.com/labxstudio"><Icon icon="fa6-brands:twitter" /></a>
      <a className="cs-center" href="https://www.linkedin.com/company/labxstudio/"><Icon icon="fa6-brands:linkedin-in" /></a>
      <a className="cs-center" href="https://instagram.com/labx.studio"><Icon icon="fa6-brands:instagram" /></a>
      <a className="cs-center" href="https://www.youtube.com/@LabXStudio"><Icon icon="fa6-brands:youtube" /></a>
      <a className="cs-center" href="https://dribbble.com/labxstudio"><Icon icon="fa6-brands:dribbble" /></a>
      <a className="cs-center" href="https://behance.net/labxstudio"><Icon icon="fa6-brands:behance" /></a>
    </Div>
  )
}
