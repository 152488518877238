import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactForm() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const notify = () => toast("Wow so easy!");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDatab = new FormData(e.target);
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyKORQThaD-rIkUJUJrzFbq_HXe3Rj5aq5lNkniJApl8x1lcTEIk0bE2ALMjNjc1kCe/exec",
        {
          method: "POST",
          body: formDatab,
        }
      );
      const data = await response.json();
      console.log(data);
      // Handle success or error response
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="App">
      <h1>Contact Me form</h1>
      <h2>
        This demonstrates how to send data from a website form to Google sheet
        in React or Vanilla jS
      </h2>
      <ToastContainer />
      <div>
        <form className="form" onSubmit={handleSubmit}>
          <input
            placeholder="Your Name"
            name="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            placeholder="Your Email"
            name="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            placeholder="Your Message"
            name="Message"
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <button onClick={notify} type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;