import React from 'react'
import Div from '../Div'
import './logolist.scss'

export default function LogoList() {
  const partnerLogos = [
    {
      src: '/images/iyigirisim.png', 
      alt:'İyi Girişim',
      links: "https://iyigirisim.net/"
    },
    {
      src: '/images/obmforall.png', 
      alt:'OBM for All',
      links: "#"
    },
    {
      src: '/images/elmali.png', 
      alt:'Elmalı tech',
      links: "https://www.elmaligroup.com/"
    },
    {
      src: '/images/regantplus.png', 
      alt:'Regant Plus',
      links: "https://regantplus.com/"
    },
    {
      src: '/images/simurgify.png', 
      alt:'Simurgify',
      links: "#"
    },
    {
      src: '/images/longstage.png', 
      alt:'Longstage',
      links: "https://longstage.com/"
    }
  ]
  return (
    <Div className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index)=><div className="cs-partner_logo" key={index}> <a href={partnerLogo.links}>
 <img src={partnerLogo.src} alt={partnerLogo.alt} /></a></div>)}
    </Div>
  )
}
