import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'What is UI/UX design?',
      answer: 'UI/UX design refers to the process of designing user interfaces and experiences for digital products or services, such as websites, mobile apps, or software applications.'
    },
    {
      question: 'What is the difference between UI and UX design?',
      answer: "UI design focuses on the look and feel of a product's visual elements, while UX design focuses on creating a seamless and enjoyable user experience that meets the needs of the user."
    },
    {
      question: 'How do you ensure that the design meets my needs?',
      answer: "We work closely with our clients to ensure that the design meets their specific needs and goals. We conduct research and gather feedback throughout the design process to ensure that the final product is tailored to the client's requirements."
    },
    {
      question: 'What types of industries do you work with?',
      answer: 'We work with a wide range of industries, including technology, healthcare, education, finance, and more. We have experience designing for various industries and can adapt our approach to meet the specific needs of each client.'
    },
    {
      question: 'How do you approach a new design project?',
      answer: "Our design process is collaborative and iterative. We start by gathering information and conducting research to understand the client's goals and target audience. From there, we develop concepts and prototypes, gather feedback, and refine the design until we achieve a final product that meets the client's needs."
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
